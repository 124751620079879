import { createApp } from 'vue'
import App from './App.vue'
import 'amfe-flexible'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import 'normalize.css'
import '@/assets/css/index.css'
import {VueMasonryPlugin} from 'vue-masonry'

createApp(App).
use(store).
use(router).
use(Vant).
use(VueMasonryPlugin)
.mount('#app')
