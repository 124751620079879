'use strict'
// 跨域请求的地址
var domain = '/api'

// 服务端地址
//var target = 'http://localhost:9090/'
var target = 'https://apivol.shuahuo.cn/'
// 正式环境
var socketUrl = 'ws://47.94.160.9:8686/ws'
//前端页面的地址
var fontUrl = 'https://vol.shuahuo.cn/'

//邀请好友的地址
var inviteUrl = 'https://vol.shuahuo.cn/#/login/?userId='

module.exports = {
  domain: domain,
  target: target,
  socketUrl: socketUrl,
  fontUrl:fontUrl,
  inviteUrl:inviteUrl
}



