<template>
  <template v-if="navShow">
    <Header v-show="navShow" :title="title" :isleftarrow="isleftarrow" :hasBg="hasBg"></Header>
  </template>
  <router-view v-slot="{ Component }" >
    <keep-alive :key="$route.path">
      <component :is="Component" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <component :is="Component" v-if="!$route.meta.keepAlive" />
    <div v-if="tabbarShow">
      <Bottomtabbar></Bottomtabbar>
    </div>
  </router-view>
</template>

<script>

import Bottomtabbar from '@/components/Bottomtabbar'
import Header from '@/components/Header.vue'

export default {
  name: 'App',
  components: {
   Bottomtabbar,
   Header
  },
  data () {
    return {
      title: '',
      isleftarrow: '',
      transitionName: 'fade',
      navShow: true,
      tabbarShow: false,
      hasBg: false,
    }
  },
    mounted () {
    this.title = this.$route.meta.title
    this.isleftarrow = this.$route.meta.isleftarrow
    this.navShow = this.$route.meta.navShow
    this.tabbarShow = this.$route.meta.tabbarShow
    this.hasBg = this.$route.meta.hasBg
  },
    watch: {
    $route (to) {
      this.title = to.meta.title
      this.isleftarrow = to.meta.isleftarrow
      this.navShow = to.meta.navShow
      this.tabbarShow = to.meta.tabbarShow
      this.hasBg = this.$route.meta.hasBg
    }
  }
}
</script>

<style>

</style>
