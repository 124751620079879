

//获取活动分类
import request from "@/utils/request";

export function getButtomTabList(){
    return request({
        url: '/appreq/buttomtab/list',
        headers: {
            isToken: false
        },
        method: 'get',
    })
}
